// @packages
import { Route, Routes } from "react-router-dom";

// @app
import { WorkshopRoutes } from "../workshop/routes/WorkshopRoutes";

export const AppRouter = () => {
  return (
    <Routes>
      <Route path='/*' element={<WorkshopRoutes />} />
    </Routes>
  )
}
