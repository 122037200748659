import { Navigate, Route, Routes } from "react-router-dom";
import { PageDetail, WorkshopPage } from "../pages";

export const WorkshopRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<WorkshopPage />} />
      <Route path="/:idItem" element={<PageDetail />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  )
}