// @packages
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material"

interface CardProps {
  commonName: string | null;
  family: string | null;
  id: string;
  scientificName: string | null;
  characteristics: string[];
}

export const CardItem: React.FC<CardProps> = ({
  id,
  scientificName,
  family,
  commonName,
}) => {
  const url = `/${id}`;
  const imageUrl = `/assets/${id}.jpg`;

  return (
    <Grid
      item
      key={id}
      md={4}
      sm={6}
      xs={12}
    >
      <Card>
        <CardMedia
          image={imageUrl}
          sx={{ height: 500 }}
          title={scientificName || ''}
        />
        <CardContent sx={{ textAlign: "center" }}>
          <Typography gutterBottom variant="h5" component="div">
            {scientificName || family}
          </Typography>
          <Typography gutterBottom component="p">
            {`(${commonName || family})`}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", paddingBottom: "15px" }}>
          <Button
            color="primary"
            component={RouterLink}
            to={url}
            variant="contained"
          >
            Ver más
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}
