// @packages
import React, { ReactNode } from 'react';
import { CssBaseline, ThemeProvider } from "@mui/material";

// @app
import { customTheme } from "./";

interface AppThemeProps {
  children: ReactNode;
}

export const AppTheme: React.FC<AppThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
