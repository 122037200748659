// @own
import { Typography } from '@mui/material';
import './styles.scss';

export const MainFooter = () => {
  return (
    <footer className='main-footer'>
      <Typography sx={{ textAlign: "center", fontSize: "0.9rem" }}>
        Pata Roja Software. Todos los derechos reservados 2024.
      </Typography>
    </footer>
  )
}
