export const workshop = [
  {
    characteristics: [
      'Árboles de hasta 20 m de alto, de copa extendida y redondeada, deja caer sus hojas cada año en la época fría.',
      'Hojas con la lámina dividida en muchos segmentos pequeños, verdes, sin tricomas.',
      'Flores en grupos de hasta 25 cm de largo, moradas, con los pétalos fusionados en un tubo, de aproximadamente 4 cm de largo.',
      'Frutos circulares, aplanados, de aproximadamente 5 a 7 cm de diámetro, duros, color café claro a gris, en la madurez se abren en dos partes; semillas planas, aladas',
    ],
    commonName: "Jacaranda",
    family: null,
    id: "jacaranda-mimosifolia",
    scientificName: "Jacaranda mimosifolia",
  },
  {
    characteristics: [
      'Las especies del género Philodendron son venenosas para los vertebrados, pero varian en sus niveles de toxicidad.',
      'Contienen cristales de calcio , los cuales son venenosos e irritantes.',
      'La savia puede causar irritación de piel, masticando y/o ingiriendo las partes de la planta puede resultar en hinchamiento severo y puede comprometer las vías respiratorias.',
    ],
    commonName: "Garra de león",
    family: null,
    id: "philodendron",
    scientificName: "Philodendron",
  },
  {
    characteristics: [
      'El género Ficus contiene alrededor de 900 taxones específicos e infraespecíficos aceptados de árboles, arbustos y trepadoras de la familia Moraceae, tribu monogenérica Ficeae, oriundas de la zona intertropical, con algunas de ellas distribuidas por las regiones templadas.',
      'La mayoría son perennes, excepto las que vegetan en latitudes no tropicales y zonas con una larga estación seca.',
    ],
    commonName: null,
    family: "Ficus",
    id: "ficus-1",
    scientificName: null,
  },
  {
    characteristics: [
      'El género Ficus contiene alrededor de 900 taxones específicos e infraespecíficos aceptados de árboles, arbustos y trepadoras de la familia Moraceae, tribu monogenérica Ficeae, oriundas de la zona intertropical, con algunas de ellas distribuidas por las regiones templadas.',
      'La mayoría son perennes, excepto las que vegetan en latitudes no tropicales y zonas con una larga estación seca.',
    ],
    commonName: null,
    family: "Ficus",
    id: "ficus-2",
    scientificName: null,
  },
  {
    characteristics: [
      'Árboles hasta de 10 m de altura, caducifolios, muy ramificados, de madera suave.',
      'Hojas coriáceas, largo-pecioladas, palmadas, 3 a 5 lobuladas, ampliamente ovadas a casi orbiculares, 10 a 20 cm de largo, ásperas en el haz, pubescentes en el envés.',
      'Flores en estructuras especiales llamadas siconos o higos.',
      'Frutos como síconos, carnosos, en forma de pera.',
    ],
    commonName: "Higuera",
    family: null,
    id: "ficus-carica",
    scientificName: "Ficus carica",
  },
  {
    characteristics: [
      'Son árboles de hoja caduca, copas amplias, que pueden alcanzar hasta 35 m de altura y 2 de diámetro.',
      'Su fuste es recto y cilíndrico con corteza gruesa de color castaño.',
      'Es una especie dioica, que florece en primavera.',
      'El fruto es una cápsula con numerosas semillas que pierden rápidamente su capacidad germinativa.',
    ],
    commonName: "Álamo",
    family: null,
    id: "populus-alba",
    scientificName: "Populus alba",
  },
  {
    characteristics: [
      'Arbustos o pequeños árboles de hasta 2 m de alto, con ramas espinosas.',
      'Hojas obovadas a ovadas, márgenes enteros a finamente dentados, haz verde oscuro y glabro, envés verde pálido y pubescente cuando joven.',
      'Flores blancas, ca. 1 cm de ancho, en inflorescencias tipo corimbo.',
      'Frutos subesféricos, ca. 1 cm de diámetro, en densos racimos, rojo-brillantes.',
    ],
    commonName: "Piracanto manzanitas",
    family: null,
    id: "pyraccantha-coccinea",
    scientificName: "Pyraccantha coccinea",
  },
  {
    characteristics: [
      'Son arbustos o árboles pequeños, algunos trepadores perennes en las zonas increíblemente lluviosas durante todo el año, o bien caducifolios en las de estación seca; de entre 1 hasta 12 m de altura.',
      'Se sujetan en otras plantas usando sus afiladas púas que tienen la punta cubierta de una sustancia cerosa negra.',
      'Las hojas son alternas, simples y de forma ovalado-acuminada de 4-12 cm de largo y 2-6 cm de ancho.',
      'Las flores, hermafroditas, son axilares, conspicuas, tubulares, con 5-6 lóbulos cortos, generalmente blancas, organizadas en grupos de 3, cada una insertada en una bráctea persistente de aspecto papiráceo y habitualmente vivamente coloreada de blanco, amarillo, rosado, magenta, morado, rojo, anaranjado, verde o café.',
      'El número de estambres varía de 5 a 10; con filamentos cortos y soldados en la base.',
      'El ovario es fusiforme, glabro o pubescente, con estilo lateral corto.',
      'El fruto es un aquenio pentámero estrecho, fusiforme o cilíndrico.',
    ],
    commonName: "Veranera",
    family: null,
    id: "bougainvillea",
    scientificName: "Bougainvillea",
  },
  {
    characteristics: [
      'Arbustos muy ramificados de hasta 5 m de altura.',
      'Tallos verdes que con el tiempo pasan a grises, laxos.',
      'Hojas gruesas y coriáceas, lanceoladas, color verde oscuro en el haz, verde grisáceo en el envés.',
      'Flores blancas, amarillas, rosas, o rojas de hasta 4cm de diámetro, con un número variable se pétalos, formando ramilletes en el extremo de las ramas.',
      'Frutos tipo folículos, alargados y secos; semillas aplanadas, plumosas.',
    ],
    commonName: "Laurel",
    family: null,
    id: "nerium-oleander-1",
    scientificName: "Nerium Oleander 1",
  },
  {
    characteristics: [
      'Arbustos muy ramificados de hasta 5 m de altura.',
      'Tallos verdes que con el tiempo pasan a grises, laxos.',
      'Hojas gruesas y coriáceas, lanceoladas, color verde oscuro en el haz, verde grisáceo en el envés.',
      'Flores blancas, amarillas, rosas, o rojas de hasta 4cm de diámetro, con un número variable se pétalos, formando ramilletes en el extremo de las ramas.',
      'Frutos tipo folículos, alargados y secos; semillas aplanadas, plumosas.',
    ],
    commonName: "Laurel",
    family: null,
    id: "nerium-oleander-2",
    scientificName: "Nerium Oleander 2",
  },
  {
    characteristics: [
      'Es un árbol pequeño de 3 a 6m de altura, con su tallo semileñoso.',
      'Las hojas son brillantes y más largas que anchas, de color verde-rojizo.',
      'Tiene flores vistosas de color rojo o naranja con numerosos estambres que asemejan hilos.',
      'Los frutos son globosos y rojos con una especie de corona en un extremo, con muchas semillas, carnosas, comestibles y que son de color rojo.',
    ],
    commonName: "Granada",
    family: null,
    id: "punica-granatum",
    scientificName: "Punica granatum",
  }
];
