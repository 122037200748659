// @packages
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

// @app
import { getPlantById } from '../../helpers/getPlantById';
import { PageDatailLayout } from '../../layout/PageDatailLayout';
import { PlantInfo } from '../../components/PlantInfo/index';

// @own
import './styles.scss';

export const PageDetail = () => {
  const { idItem } = useParams();
  let currentPlant;

  if (idItem) {
    currentPlant = getPlantById(idItem);
  }

  if (!idItem || !currentPlant) {
    return (
      <PageDatailLayout>
        <Typography gutterBottom variant='h2' component="h2">
          Algo ha fallado <br /> Regresa a la página principal
        </Typography>
        <Button
          color="primary"
          component={RouterLink}
          to="/"
          variant="contained"
        >
          Regresar a la página principal
        </Button>
      </PageDatailLayout>
    )
  }

  return (
    <PageDatailLayout>
      <PlantInfo {...currentPlant} />
    </PageDatailLayout>
  )
}
