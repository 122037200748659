// @packages
import React, { ReactNode } from "react";
import { Container } from "@mui/material";
import { MainHeader } from "../components/MainHeader";
import { MainFooter } from "../components/MainFooter";

interface AppThemeProps {
  children: ReactNode;
}

export const PageDatailLayout: React.FC<AppThemeProps> = ({ children }) => {

  return (
    <>
      <MainHeader />

      <Container
        maxWidth="lg"
        sx={{ pt: 3, pb: 8 }}
      >
        {children}
      </Container>

      <MainFooter />
    </>
  )
}
