// @packages
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

// @own
import './styles.scss';

export const MainHeader = () => {
  return (
    <div className='main-header'>
      <div className="main-header__logos">
        <Link component={RouterLink} to="/">
          <img src="/assets/unam-logo.jpg" alt="FAD Logo" />
          <img src="/assets/fad-logo.jpg" alt="UNAM Logo" />
        </Link>
      </div>
    </div>
  )
}
