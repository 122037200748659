import { workshop } from "../../data/workshop";

interface Plant {
  commonName: string | null;
  family: string | null;
  id: string;
  scientificName: string | null;
  characteristics: string[];
}

export const getPlantById = (idPlant: string | undefined): Plant | void | undefined => {
  return workshop.find((plant => (plant.id === idPlant)));
}
