// @packages
import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface PlantInfoProps {
  commonName: string | null;
  family: string | null;
  id: string;
  scientificName: string | null;
  characteristics: string[];
}

export const PlantInfo: React.FC<PlantInfoProps> = ({
  commonName,
  family,
  id,
  scientificName,
  characteristics,
}) => {
  const imageUrl = `/assets/${id}.jpg`;

  return (
    <div>
      <header>
        <Typography gutterBottom variant='h2' component="h2">
          {scientificName || family}
        </Typography>

        <Grid sx={{ maxWidth: "650px" }}>
          <img src={imageUrl} alt={scientificName || ''} />
        </Grid>
      </header>

      <main>
        <Typography
          component="ul"
          variant="body1"
          style={{
            fontSize: "20px",
            padding: '25px 0 60px 20px',
          }}
        >
          {!!scientificName && (
            <li><strong>Nombre científico:</strong> {scientificName}</li>
          )}
          {!!commonName && (
            <li><strong>Nombre común:</strong> {commonName}</li>
          )}
          {!!family && (
            <li><strong>Familia:</strong> {family}</li>
          )}
          {!!characteristics && (
            <li><strong>Características:</strong>
              <Typography
                component="ul"
                variant="body1"
                style={{
                  fontSize: "20px",
                  padding: '10px 30px',
                }}
              >
                {characteristics.map((plant, index) => (
                  <li key={index}>{plant}</li>
                ))}
              </Typography>
            </li>
          )}
        </Typography>
        <Button
          color="primary"
          component={RouterLink}
          to="/"
          variant="contained"
        >
          Regresar a la página principal
        </Button>
      </main>
    </div>
  )
}
