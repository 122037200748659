// @packages
import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: '#4A4A4A',
    },
    secondary: {
      main: '#543884',
    },
    error: {
      main: red.A400,
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536,
    },
  },
});
