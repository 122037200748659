// @packages
import { Grid, Typography } from '@mui/material';

// @app
import { PageDatailLayout } from '../../layout/PageDatailLayout';
import { CardItem } from '../../components/CardItem';
import { workshop } from '../../../data/workshop';

// @own
import './styles.scss';

export const WorkshopPage = () => {
  return (
    <PageDatailLayout>
      <Typography
        gutterBottom
        variant='h2'
        component="h2"
        sx={{
          padding: "30px 0",
          fontSize: {
            md: "3.75rem",
            sm: "3rem",
            xs: "2.5rem"
          }
        }}
      >
        Introducción a la ilustración científica: conociendo y dibujando las plantas de nuestra facultad
      </Typography>

      <Typography
        gutterBottom
        variant='h4'
        component="h4"
        sx={{
          paddingBottom: "50px",
          fontSize: {
            md: "2.125rem",
            sm: "1.8rem",
            xs: "1.5rem"
          }
        }}
      >
        Brenda Rojas y Rafael Torres
      </Typography>

      <Grid
        container
        spacing={4}
      >
        {workshop.map((item) => (
          <CardItem key={item.id} {...item} />
        ))}
      </Grid>
    </PageDatailLayout>
  )
}
